import { VCard } from 'vuetify/lib/components/VCard';
import { VCardActions } from 'vuetify/lib/components/VCard';
import { VCardText } from 'vuetify/lib/components/VCard';
import { VCardTitle } from 'vuetify/lib/components/VCard';
import { VContainer } from 'vuetify/lib/components/VGrid';
import { VList } from 'vuetify/lib/components/VList';
import { VListItem } from 'vuetify/lib/components/VList';
import { VListItemActionText } from 'vuetify/lib/components/VList';
import { VListItemTitle } from 'vuetify/lib/components/VList';
import { VPagination } from 'vuetify/lib/components/VPagination';
import { VSheet } from 'vuetify/lib/components/VSheet';
import { VSkeletonLoader } from 'vuetify/lib/components/VSkeletonLoader';
import { VTooltip } from 'vuetify/lib/components/VTooltip';

var render = function render(){var _vm=this,_c=_vm._self._c,_setup=_vm._self._setupProxy;return _c(VContainer,[_c(VSheet,{attrs:{"rounded":"lg"}},[_c(VCard,[_c(VCardTitle,[_vm._v("Agendas")]),_c(VCardText,[(_vm.isAppLoading)?_c(VSkeletonLoader,{attrs:{"type":"table","max-width":"50%"}}):_c(VList,{attrs:{"max-width":"50%"}},_vm._l((_vm.agendas),function(a){return _c(VListItem,{key:a.id,attrs:{"to":`/${_vm.linkDateFormat(a.date)}`}},[_c(VListItemTitle,[_vm._v(" "+_vm._s(_vm.listDateFormat(a.date))+" ")]),_c(VListItemActionText,[_c(VTooltip,{attrs:{"left":""},scopedSlots:_vm._u([{key:"activator",fn:function({ on }){return [_c('span',_vm._g({},on),[_vm._v(" "+_vm._s(a.items.length)+" ")])]}}],null,true)},[_c('ul',_vm._l((a.items),function(item){return _c('li',{key:item.id},[_vm._v(" "+_vm._s(item.title)+" ")])}),0)])],1)],1)}),1)],1),_c(VCardActions,{staticClass:"justify-center"},[_c(VPagination,{attrs:{"length":_vm.totalPages,"total-visible":7},on:{"input":_vm.changePage},model:{value:(_vm.page),callback:function ($$v) {_vm.page=$$v},expression:"page"}})],1)],1)],1)],1)
}
var staticRenderFns = []

export { render, staticRenderFns }