
import { Component, Vue } from "vue-property-decorator";
import { Action, Getter } from "vuex-class";

import type { Agenda } from "~/data/Agenda";
import type { AgendaList } from "~/types/Agenda";

@Component({})
export default class AgendasView extends Vue {
  agendas: Agenda[] | null = null;
  page = 1;
  totalPages = 1;
  pageSize = 10;
  loading = false;

  @Action("GET_AGENDA_LIST")
  getAgendaList!: (opts: unknown) => AgendaList;

  @Getter
  isAppLoading!: boolean;

  changePage(newPage: number): void {
    this.loadList(newPage);
  }

  linkDateFormat(date: Date): string {
    const y = date.getUTCFullYear();
    const m = `0${date.getUTCMonth() + 1}`.substr(-2);
    const d = `0${date.getUTCDate()}`.substr(-2);
    return `${y}-${m}-${d}`;
  }

  listDateFormat(date: Date): string {
    console.log(date);
    const y = date.getUTCFullYear();
    const d = date.getDate();
    const dow = date.getDay();
    const m = date.getUTCMonth();
    const days = [
      "Monday",
      "Tuesday",
      "Wednesday",
      "Thursday",
      "Friday",
      "Saturday",
      "Sunday",
    ];
    const months = [
      "Jan",
      "Feb",
      "Mar",
      "Apr",
      "May",
      "Jun",
      "Jul",
      "Aug",
      "Sep",
      "Oct",
      "Nov",
      "Dec",
    ];
    return `${days[dow]} ${months[m]} ${d} ${y}`;
  }

  async loadList(fetchPage = 1): Promise<void> {
    const skip = this.pageSize * (fetchPage - 1);
    this.loading = true;
    try {
      const { agendas, page, total } = await this.getAgendaList({
        limit: this.pageSize,
        skip,
      });

      this.agendas = agendas as Agenda[];
      this.page = page;
      this.totalPages = Math.ceil(total / this.pageSize);
    } finally {
      this.loading = false;
    }
  }

  async created(): Promise<void> {
    await this.loadList();
  }
}
